
import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import { NavigationTabs, SearchTabs, AuthorResult, CollabDistance, getCollaborationDistance, CollabDistanceTable } from '@/components/freetools'
import { isNumber } from '@/utils/utils'
import { FreeToolsAPI } from '@/api/freetools-api'

@Component({
  components: {
    NavigationTabs,
    CollabDistanceTable,
  },
})
export default class CollaborationDistance extends Vue {
  @Ref() readonly sourceCol!: HTMLElement
  @Ref() readonly targetCol!: HTMLElement
  @Ref() readonly targetUseErdosButton!: HTMLElement

  //
  // REACTIVE PROPERTIES
  //
  selectedTab = SearchTabs.collabDistance
  sourceNameInputText = ''
  targetNameInputText = ''
  sourceNames: AuthorResult[] = []
  targetNames: AuthorResult[] = []
  selectedSourceAuthor: AuthorResult | null = null
  selectedTargetAuthor: AuthorResult | null = null
  collabDistance: CollabDistance[] = []
  sourceDropdownWidth = 0
  targetDropdownWidth = 0
  loading = false
  loadingSourceAuthorList = false
  loadingTargetAuthorList = false
  noResultsSourceAuthor = false
  noResultsTargetAuthor = false
  message = ''
  showNewSearchButton = false

  //
  // WATCHERS
  //
  @Watch('$route', { immediate: true })
  async onRouteChanged() {
    const source = this.$route.query.source as unknown as number
    const target = this.$route.query.target as unknown as number

    this.resetToInitialState({
      ignoreSource: this.$route.query.source !== undefined,
      ignoreTarget: this.$route.query.target !== undefined,
    })

    if (
      source !== null && target !== null &&
      !isNaN(source) && !isNaN(target)
    ) {
      const sourceAuthorId = parseInt(source as unknown as string)
      const targetAuthorId = parseInt(target as unknown as string)

      this.loadingSourceAuthorList = true
      this.loadingTargetAuthorList = true

      const authors = await FreeToolsAPI.getAuthorsByIds([sourceAuthorId, targetAuthorId], 2)

      this.selectedSourceAuthor = authors.find(author => author.id === sourceAuthorId) || null
      this.selectedTargetAuthor = authors.find(author => author.id === targetAuthorId) || null

      this.loadingSourceAuthorList = false
      this.loadingTargetAuthorList = false

      this.searchCollabDistance()
    } else if (source !== null && !isNaN(source)) {
      const sourceAuthorId = parseInt(source as unknown as string)

      this.loadingSourceAuthorList = true

      const authors = await FreeToolsAPI.getAuthorsByIds([sourceAuthorId], 1)

      this.selectedSourceAuthor = authors.find(author => author.id === sourceAuthorId) || null

      this.loadingSourceAuthorList = false
    }
  }

  //
  // COMPUTED PROPERTIES
  //
  get selectedSourceAuthorName() {
    return this.selectedSourceAuthor ? this.selectedSourceAuthor.name : 'Select Author'
  }

  get selectedTargetAuthorName() {
    return this.selectedTargetAuthor ? this.selectedTargetAuthor.name : 'Select Author'
  }

  get selectedSourceAuthorID() {
    return this.selectedSourceAuthor ? this.selectedSourceAuthor.id : ''
  }

  get selectedTargetAuthorID() {
    return this.selectedTargetAuthor ? this.selectedTargetAuthor.id : ''
  }

  get disableMainSearchButton() {
    return (
      (this.sourceNameInputText.trim().length === 0 && !this.selectedSourceAuthor) ||
      (this.targetNameInputText.trim().length === 0 && !this.selectedTargetAuthor) ||
      (this.sourceNames.length > 0 && this.selectedSourceAuthor === null) ||
      (this.targetNames.length > 0 && this.selectedTargetAuthor === null)
    )
  }

  get showEnterNamesFirstMessage() {
    return this.sourceNameInputText.trim().length === 0 ||
      this.targetNameInputText.trim().length === 0
  }

  //
  // LIFE CYCLE HOOKS
  //
  mounted() {
    document.title = 'Collab Distance - MathSciNet'
    // window.addEventListener('resize', this.setSourceDropdownWidth)
    // window.addEventListener('resize', this.setTargetDropdownWidth)
  }

  destroyed() {
    // window.removeEventListener('resize', this.setSourceDropdownWidth)
    // window.removeEventListener('resize', this.setTargetDropdownWidth)
  }

  updated() {
    // this.setSourceDropdownWidth()
    // this.setTargetDropdownWidth()
  }

  //
  // EVENT HANDLERS
  //
  async onSearchAuthorName(type: string) {
    const totalNames = 50

    switch (type) {
      case 'source':
        if (this.sourceNameInputText.trim().length === 0) return

        this.loadingSourceAuthorList = true

        if (isNumber(this.sourceNameInputText.trim())) {
          const authors = await FreeToolsAPI.getAuthorsByIds([parseInt(this.sourceNameInputText.trim())], 1)

          this.selectedSourceAuthor = authors[0] || null
          this.sourceNames = []

          this.noResultsSourceAuthor = this.selectedSourceAuthor === null
        } else {
          this.selectedSourceAuthor = null
          this.sourceNames = (await FreeToolsAPI.getAuthorsByName(this.sourceNameInputText.trim(), totalNames))
            .map(author => ({
              id: author.id,
              name: author.name,
            }))

          this.noResultsSourceAuthor = this.sourceNames.length === 0
        }
        if (this.sourceNames.length === 1) {
          // Only one author matched, fill it in
          this.selectedSourceAuthor = this.sourceNames[0]
          this.sourceNameInputText = this.sourceNames[0].name
          this.sourceNames = []
        }
        this.loadingSourceAuthorList = false

        break
      case 'target':
        if (this.targetNameInputText.trim().length === 0) return

        this.loadingTargetAuthorList = true

        if (isNumber(this.targetNameInputText.trim())) {
          const authors = await FreeToolsAPI.getAuthorsByIds([parseInt(this.targetNameInputText.trim())], 1)

          this.selectedTargetAuthor = authors[0] || null
          this.targetNames = []

          this.noResultsTargetAuthor = this.selectedTargetAuthor === null
        } else {
          this.selectedTargetAuthor = null
          this.targetNames = (await FreeToolsAPI.getAuthorsByName(this.targetNameInputText.trim(), totalNames))
            .map(author => ({
              id: author.id,
              name: author.name,
            }))

          this.noResultsTargetAuthor = this.targetNames.length === 0
        }
        if (this.targetNames.length === 1) {
          // Only one author matched, fill it in
          this.selectedTargetAuthor = this.targetNames[0]
          this.targetNameInputText = this.targetNames[0].name
          this.targetNames = []
        }
        this.loadingTargetAuthorList = false

        break
    }
  }

  onSelectAuthorName(type: string, author: AuthorResult) {
    this.message = ''
    switch (type) {
      case 'source':
        this.selectedSourceAuthor = author
        this.sourceNameInputText = author.name
        this.sourceNames = []
        break
      case 'target':
        this.selectedTargetAuthor = author
        this.targetNameInputText = author.name
        this.targetNames = []
        break
    }
  }

  onClickNewSearch(message = '') {
    this.resetToInitialState({ message })

    this.updateRouter()
  }

  async onClickSearchCollaborationDistance() {
    if (this.disableMainSearchButton) return

    if (await this.shouldStopForUserToSelectAuthors()) return

    this.updateRouter()
  }

  async useErdos() {
    this.loadingTargetAuthorList = true

    const targetAuthorId = 189017
    const authors = await FreeToolsAPI.getAuthorsByIds([targetAuthorId], 1)
    this.selectedTargetAuthor = authors.find(author => author.id === targetAuthorId) || null

    this.loadingTargetAuthorList = false

    if (this.selectedSourceAuthor) {
      this.onClickSearchCollaborationDistance()
    }
  }

  onChangeAuthorName(type: string) {
    this.showNewSearchButton = false
    this.collabDistance = []

    switch (type) {
      case 'source':
        this.selectedSourceAuthor = null
        this.sourceNameInputText = ''
        this.sourceNames = []
        break
      case 'target':
        this.selectedTargetAuthor = null
        this.targetNameInputText = ''
        this.targetNames = []
        break
    }
  }

  //
  // METHODS
  //
  async searchCollabDistance() {
    try {
      this.collabDistance = []
      this.message = ''
      this.loading = true

      const collabDistance = await getCollaborationDistance(
        this.selectedSourceAuthor?.id,
        this.selectedTargetAuthor?.id,
      )

      if (collabDistance) {
        this.collabDistance = collabDistance
      } else {
        this.message = 'No Collaboration Distance Found'
      }

      this.showNewSearchButton = true
    } catch (err) {
      this.onClickNewSearch('Collaboration Distance Service is not available')
    }

    this.loading = false
  }

  resetToInitialState(options: {
      message?: string,
      ignoreSource?: boolean,
      ignoreTarget?: boolean,
    } | undefined = undefined
  ) {
    if (!options || (options && !options.ignoreSource)) {
      this.sourceNameInputText = ''
      this.sourceNames = []
      this.selectedSourceAuthor = null
      this.loadingSourceAuthorList = false
      this.noResultsSourceAuthor = false
    }

    if (!options || (options && !options.ignoreTarget)) {
      this.targetNameInputText = ''
      this.targetNames = []
      this.selectedTargetAuthor = null
      this.loadingTargetAuthorList = false
      this.noResultsTargetAuthor = false
    }

    this.collabDistance = []
    this.loading = false
    this.message = options && options.message ? options.message : ''

    this.showNewSearchButton = false
  }

  async shouldStopForUserToSelectAuthors() {
    let sourceNamesPromise, targetNamesPromise

    if (this.selectedSourceAuthor === null) {
      sourceNamesPromise = this.onSearchAuthorName('source')
    }

    if (this.selectedTargetAuthor === null) {
      targetNamesPromise = this.onSearchAuthorName('target')
    }

    await Promise.all([sourceNamesPromise, targetNamesPromise])

    return this.selectedSourceAuthor === null || this.selectedTargetAuthor === null
  }

  updateRouter(replace = false) {
    const info = {
      name: 'FreeToolsCollabDist',
      query: {
        source: this.selectedSourceAuthor?.id.toString(),
        target: this.selectedTargetAuthor?.id.toString(),
      },
    }

    if (replace) {
      this.$router.replace(info)
    } else {
      this.$router.push(info)
    }
  }
}
