
import { Vue, Component, Watch } from 'vue-property-decorator'
import { NavigationTabs, SearchTabs, JournalsResult } from '@/components/freetools'
import { FreeToolsAPI } from '@/api/freetools-api'
import { updateMathJax } from '@/utils/utils'

type Option = {
  text: string
  value: string
}

@Component({
  components: {
    NavigationTabs,
  },
})
export default class CurrentJournals extends Vue {
  //
  // REACTIVE PROPERTIES
  //
  selectedTab = SearchTabs.currentJournals
  journals: JournalsResult[] = []
  totalResults = 0
  dropdownOptions: Option[] = [
    { text: 'Select a week', value: '' },
    { text: 'This week', value: '0' },
    { text: 'Last week', value: '1' },
    { text: '2 weeks ago', value: '2' },
    { text: '3 weeks ago', value: '3' },
    { text: '4 weeks ago', value: '4' },
    { text: '5 weeks ago', value: '5' },
    { text: '6 weeks ago', value: '6' },
  ]

  selectedDropdownOption = this.dropdownOptions[0].value

  pageSize = 20
  currentPage = 1
  loading = false
  noResults = false

  //
  // COMPUTED PROPERTIES
  //

  get noResultsText() {
    return this.dropdownOptions.find(option => option.value === this.selectedDropdownOption)?.text.toLowerCase()
  }

  get totalPaginationRows() {
    return this.totalResults > 1000 ? 1000 : this.totalResults
  }

  //
  // WATCHERS
  //
  @Watch('$route', { immediate: true })
  onRouteChanged() {
    const week = this.$route.query.week as unknown as number
    const page = this.$route.query.page as unknown as number
    const size = this.$route.query.size as unknown as number

    this.currentPage = page && !isNaN(page) ? page : 1
    this.pageSize = size && !isNaN(size) ? size : 20

    if (week && !isNaN(week)) {
      const w = week as unknown as string
      const opt = this.dropdownOptions.find(option => option.value === w)
      this.selectedDropdownOption = opt ? opt.value : this.dropdownOptions[0].value
    }

    this.onSearch()
  }

  @Watch('selectedDropdownOption')
  onSelectedDropdownOptionChanged() {
    if (this.selectedDropdownOption.trim().length === 0) {
      this.clearResults()
    }

    this.updateRouter()
  }

  //
  // LIFE CYCLE HOOKS
  //
  mounted() {
    updateMathJax()
  }

  updated() {
    updateMathJax()
  }

  //
  // EVENT HANDLERS
  //

  async onSearch() {
    if (this.selectedDropdownOption.trim().length === 0) return

    this.loading = true
    this.noResults = false

    const result = await FreeToolsAPI.getJournalsByWeekBack(
      this.selectedDropdownOption,
      this.currentPage,
      this.pageSize,
    )

    this.noResults = result.results.length === 0
    this.journals = result.results
    this.totalResults = result.total
    this.loading = false
  }

  onClickClear() {
    this.selectedDropdownOption = this.dropdownOptions[0].value
    this.clearResults()
  }

  updateCurrentPage(currentPage: number) {
    this.currentPage = currentPage
    this.updateRouter()
  }

  //
  // METHODS
  //
  clearResults() {
    this.currentPage = 1
    this.journals = []
    this.totalResults = 0
  }

  updateRouter(replace = false) {
    const info = {
      name: 'FreeToolsCurJournals',
      query: this.selectedDropdownOption
        ? {
          week: this.selectedDropdownOption.toString(),
          page: this.currentPage.toString(),
          size: this.pageSize.toString(),
        }
        : {},
    }

    if (replace) {
      this.$router.replace(info)
    } else {
      this.$router.push(info)
    }
  }
}
