
import { Vue, Component } from 'vue-property-decorator'
import { NavigationTabs, SearchTabs, FreeToolsMSCResult } from '@/components/freetools'
import { FreeToolsAPI } from '@/api/freetools-api'
import { updateMathJax } from '@/utils/utils'
import { AppConfigAPI } from '@/api'
import { AppConfig } from '@/api/types'

type Option = {
  text: string
  value: string
}

@Component({
  components: {
    NavigationTabs,
  },
})
export default class CurrentPublicationsS extends Vue {
  //
  // REACTIVE PROPERTIES
  //
  selectedTab = SearchTabs.curretPublications
  publicationTypeOptions: Option[] = [
    {
      text: 'All',
      value: 'all',
    },
    {
      text: 'Books',
      value: 'books',
    },
    {
      text: 'Journals',
      value: 'journals',
    },
    {
      text: 'Proceedings',
      value: 'proceedings',
    },
  ]

  publicationStatusOptions: Option[] = [
    {
      text: 'Indexed',
      value: 'indexed',
    },
    {
      text: 'Reviewed',
      value: 'reviewed',
    },
  ]

  timeFrameOptions: Option[] = [
    {
      text: 'This month',
      value: '0',
    },
    {
      text: 'Last month',
      value: '1',
    },
    {
      text: '2 months ago',
      value: '2',
    },
    {
      text: '3 months ago',
      value: '3',
    },
    {
      text: '4 months ago',
      value: '4',
    },
    {
      text: '5 months ago',
      value: '5',
    },
    {
      text: '6 months ago',
      value: '6',
    },
  ]

  classifications: FreeToolsMSCResult[] = []

  selectedTimeFrame = this.timeFrameOptions[0].value
  selectedPublicationType = this.publicationTypeOptions[0].value
  selectedPublicationStatus = this.publicationStatusOptions[0].value
  selectedClassification = 'all'
  classificationSearchText = ''
  // appConfig: null | AppConfig = null

  //
  // COMPUTE PROPERTIES
  //
  get appConfig() {
    return this.$store.getters.appConfig
  }

  get validClassifications() {
    const classifications = this.classifications
      .filter(c => c.endYear.trim().toLowerCase() === 'current')
      .map(c => {
        const value = c.code.trim().replace(/-XX/, '')
        const text = c.description
          .replace(/\[.*\]|\{.*\}|\(.*\)|;.*|For.*/g, '')
          .replace(/\$K\$/g, 'K')
          .trim()

        return { text: `${value} - ${text}`, value }
      })

    return [
      {
        text: 'All Classifications',
        value: 'all',
      },
      ...classifications,
    ]
  }

  //
  // LIFE CYCLE HOOKS
  //
  async mounted() {
    document.title = 'Current Publications - MathSciNet'
    this.fetchClassifications()
    updateMathJax()
    // this.appConfig = await AppConfigAPI.getAppConfig()
  }

  updated() {
    updateMathJax()
  }

  //
  // EVENT HANDLERS
  //

  onClickSearchHandler() {
    const pubStatus = this.selectedPublicationStatus.trim().toLowerCase()
    const primaryClass = this.selectedClassification === 'all'
      ? this.classificationSearchText.trim() === ''
        ? ''
        : this.classificationSearchText
      : this.selectedClassification

    const entryType = () => {
      const parsePublicationType = (type: string) => {
        switch (type.trim().toLowerCase()) {
          case 'books':
            return 'B,BCZ,JCZ'
          case 'journals':
            return 'J,JC,JT'
          case 'proceedings':
            return 'JCZ,BCZ,BC,JC'
          default:
            return ''
        }
      }

      const value = parsePublicationType(this.selectedPublicationType).trim()

      return value.length > 0 ? value : ''
    }

    if (this.appConfig && (this.appConfig.isDev || this.appConfig.internal || (this.appConfig.customerName && this.appConfig.customerName !== ''))) {
      const getDateRange = (pubStatus: string) => {
        const firstDay = new Date()
        firstDay.setDate(1)
        firstDay.setMonth(firstDay.getMonth() - parseInt(this.selectedTimeFrame))
        const lastDay = new Date()
        lastDay.setMonth(lastDay.getMonth() - parseInt(this.selectedTimeFrame) + 1) // One month (and some days) after firstDay
        lastDay.setDate(0) // -1 day of the month, which is the last day of the prior month, which is the same month as firstDay
        const stringMonth = `${firstDay.getMonth() + 1}`.padStart(2, '0')
        const stringLastDay = `${lastDay.getDate()}`.padStart(2, '0')
        const startDate = `${firstDay.getFullYear()}-${stringMonth}-01`
        const endDate = `${firstDay.getFullYear()}-${stringMonth}-${stringLastDay}`

        switch (pubStatus) {
          case 'indexed':
            return `di:[${startDate} ${endDate}]`
          case 'reviewed':
            return `dr:[${startDate} ${endDate}]`
          default:
            return ''
        }
      }

      const query = [
        getDateRange(pubStatus),
        primaryClass !== '' ? `pc:"${encodeURIComponent(primaryClass)}"` : '',
        entryType() !== '' ? `et:(${entryType().replace(/,/g, ' OR ')})` : '',
      ].filter(v => v.trim().length > 0).join(' ')

      window.location.href = `../publications-search?query=${query}`
    } else {
      const now = new Date()
      // const monthIndex = now.getMonth() - parseInt(this.selectedTimeFrame)
      now.setMonth(now.getMonth() - parseInt(this.selectedTimeFrame))
      const monthIndex = now.getMonth()
      const params = [
        `mi=${monthIndex}`,
        `ps=${pubStatus}`,
        primaryClass !== '' ? `pc=${encodeURIComponent(primaryClass)}` : '',
        entryType() !== '' ? `et=${entryType()}` : '',
      ].filter(v => v.trim().length > 0).join('&')

      window.location.href = `../publications?${params}&page=1&size=20`
    }
  }

  onClickClearHandler() {
    this.selectedTimeFrame = this.timeFrameOptions[0].value
    this.selectedClassification = 'all'
    this.classificationSearchText = ''
    this.selectedPublicationType = this.publicationTypeOptions[0].value
    this.selectedPublicationStatus = this.publicationStatusOptions[0].value
  }

  //
  // METHODS
  //
  async fetchClassifications() {
    this.classifications = await FreeToolsAPI.getFirstLevelMSC()
  }
}
